var PHASED_DEPLOYMENT_KEY = "chat-client-phased-deployment";
var getKey = function (percentage) {
    return "".concat(PHASED_DEPLOYMENT_KEY, "-").concat(percentage);
};
var phasedDeploymentToBoolean = function (value) {
    if (value === "true")
        return true;
    if (value === "false")
        return false;
    return null;
};
var getShouldBeDeployedFromSession = function (percentage) {
    var key = getKey(percentage);
    var phasedDeployment = localStorage.getItem(key);
    return phasedDeploymentToBoolean(phasedDeployment);
};
var setPhasedDeploymentForSession = function (percentage, value) {
    var key = getKey(percentage);
    localStorage.setItem(key, value.toString());
};
var shouldBeDeployedForUserSession = function (percentage) {
    if (percentage === void 0) { percentage = 100; }
    if (percentage < 0 || percentage > 100) {
        throw new Error("Percentage must be between 0 and 100 inclusive.");
    }
    if (percentage >= 100) {
        return true;
    }
    if (percentage <= 0) {
        return false;
    }
    var shouldBeDeployed = getShouldBeDeployedFromSession(percentage);
    if (shouldBeDeployed === null) {
        shouldBeDeployed = Math.random() * 100 < percentage;
        setPhasedDeploymentForSession(percentage, shouldBeDeployed);
    }
    return shouldBeDeployed;
};
export default shouldBeDeployedForUserSession;
